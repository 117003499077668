import { Pipe, PipeTransform } from '@angular/core';
import { UrlService } from '@x/ecommerce-shop/app/core/url/url.service';

@Pipe({
  name: 'taxonUrl',
})
export class TaxonUrlPipe implements PipeTransform {
  constructor(private urlService: UrlService) {}
  transform(taxon: { id: number; slug: string }, type: 'taxon' | 'pl' = 'taxon'): string {
    try {
      const { id, slug } = taxon;
      return type === 'pl'
        ? this.urlService.productListingUrl(id, slug)
        : this.urlService.taxonUrl(id, slug);
    } catch (e) {
      return '';
    }
  }
}
