import { Pipe, PipeTransform } from '@angular/core';
import { UrlService } from '@x/ecommerce-shop/app/core/url/url.service';

@Pipe({
  name: 'productUrl',
})
export class ProductUrlPipe implements PipeTransform {
  constructor(private urlService: UrlService) {}
  transform(id: number, slug?: string): string | null {
    try {
      return this.urlService.productDetailUrl(id, slug);
    } catch (e) {
      return null;
    }
  }
}
