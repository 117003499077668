import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UrlService } from '@x/ecommerce-shop/app/core/url/url.service';
import { LandingUrlPipe } from './pipes/landing-url.pipe';
import { ProductUrlPipe } from './pipes/product-url.pipe';
import { TaxonUrlPipe } from './pipes/taxon-url.pipe';

@NgModule({
  providers: [UrlService],
  imports: [CommonModule],
  declarations: [TaxonUrlPipe, ProductUrlPipe, LandingUrlPipe],
  exports: [TaxonUrlPipe, ProductUrlPipe, LandingUrlPipe],
})
export class UrlModule {}
