import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Pipe({
  name: 'landingUrl',
})
export class LandingUrlPipe implements PipeTransform {
  constructor(private route: ActivatedRoute) {}

  transform(value: string | null | undefined): any[] | string | null | undefined {
    const { channelCode } = this.route.snapshot.params;

    if (channelCode && value) return `/${channelCode}/${value}`;

    return ['.'];
  }
}
